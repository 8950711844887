#switchcontainer{
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-around;
  flex-direction: column;
  align-self: center;
  margin-bottom: 26px;
}

.switch {
    display: flex;
    width: 52px;
    height: 26px;
    transform: rotate(90deg);
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #272727;
    -webkit-transition: .4s;
    transition: .4s;
    border: 4px inset black;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 0px;
    bottom: 0px;
    background: radial-gradient(#a7a7a7, #525252);
    -webkit-transition: .2s;
    transition: .2s;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
    border: 2px solid grey;
  }