.WK {
    display: inline-block; 
    width: 50px;
    background-color: white;
    height: 120px;
    margin-left: 4px;
    border-radius: 3px;
}

.BK {
    display: inline-block; 
    width: 30px;
    background-color: black;
    height: 75px;
    transform: translateX(50%);
    margin-left: -30px;
    border-radius: 3px;
    vertical-align: top;
}

.BK.active, .WK.active, .WK:active, .BK:active {
    background-color: lightskyblue;
    transition: 0.05s linear;
}