.Keyboard{
    display: block;
    margin-top: 5px;
    border-left:solid goldenrod 3px;
    border-right:solid goldenrod 4px;
    border-top:solid goldenrod 4px;
    border-radius: 10px;
    background-color: rgb(90, 90, 90);
    max-width: max-content;
    width: 100%;
    height: auto;
    white-space: nowrap;
    align-self: center;

}


@media only screen and (max-width: 896px) {
    .Keyboard{
        flex-wrap: nowrap;
        overflow-x: scroll;
    }
}