.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  background: radial-gradient(#ffffff, #889b86);
  /* height: calc(100vh - 20px); */
  height: 100vh;
  user-select: none;
  min-height: 100%;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
}

.touchpad {
  background-color: aquamarine;
  height: 300px;
  width: 300px;
}

.Synth label#brand {
  font-family: Fantasy;
  font-size: xx-large;
}

h3{
  color: grey;
}

#SynthWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: max-content;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* background-color: red; */
}

.Synth {
  display: flex;
  flex-direction: column;
  padding: 10px 20px 0px 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  border: 5px outset rgb(66, 66, 66);
  background: linear-gradient(to top, #1a1a1a 0%, rgb(59, 59, 59) 100%);
  /* overflow-x: scroll; */
  /* width: max-content; */
}

.SliderSection {
  display: flex;
  flex-direction: row;
}

#VizSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;
}


@media screen and (max-height: 899px){
  #headline{
    display: none;
  }
}


@import url('https://fonts.googleapis.com/css?family=MonteCarlo');

#Label {
  font-family: 'MonteCarlo', cursive;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 25pt;
  color: goldenrod;
  padding-right: 10px;
}

.LabelPlacement {
  display: flex;
}

    .parent-container {
        display: flex;
        flex-direction: column;
        height: 200px; /* Fixed height or constrained height */
        border: 1px solid #ccc;
    }
    .child-container {
        overflow-x: auto;
        white-space: nowrap;
    }
    .child {
        display: inline-block;
        width: 200px;
        height: 100px;
        background-color: #f0f0f0;
        margin: 5px;
    }

::-webkit-scrollbar{
  width: 5px;
  height: 5px;
  border-radius: 10px;
}

::-webkit-scrollbar-track-piece{
  background-color: transparent;
}

::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background-color: goldenrod;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
