#Osc{
    border: 4px inset black;
    border-radius: 5px;
    padding: 0px;
    width: 100%;
    max-width: 40%;
}

@media only screen and (max-width: 375px) {
    #Osc{
        display: none;
    }
}
