.SeqCtrl{
    display: flex;
    flex-direction: row;
    width: 50px;
    height:  50px;
    border-radius: 10px;
    border: 3px outset white;
    justify-content: center;
    margin-right: 5px;
}

@media only screen and (max-width: 896px) {
    .SeqCtrl:nth-child(2), .SeqCtrl:nth-child(3){
        display: none;
    }
}

.SeqCtrl.play{
    background: radial-gradient(#ffb1b1, #ff555d);
    border: 3px outset #ffb1b1;
}

.SeqCtrl.edit{
    background: radial-gradient(#ffb1b1, #ff555d);
    border: 3px outset #ffb1b1;
}

.SeqCtrl:active{
    transform: translateY(2px);
}
 

#SeqButtons{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.arrow-right {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid dimgray;
    border-radius: 5px;
    padding: 0;
    justify-self: center;
    align-self: center;
}

.circle {
    display: flex;
    width: 25px;
    height: 25px;
    background-color: dimgray;
    border-radius: 25px;
    align-self: center;
}

.pipe{
    display: flex;
    width: 4px;
    height: 26px;
    background-color: dimgray;
    align-self: center;
    border-radius: 2px;
}
