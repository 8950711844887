.Slider{
    display: flex;
    flex-direction: column;
    width: 60px;
}

.Slider label{
    margin-top: 60px;
    height:25px;
}

input.Range {
    margin-top: 70px;
    margin-left: -31px;
    height: 10px;
    transform: rotate(270deg);
}

input[type="range"] {
    -webkit-appearance: none;
     appearance: none;
     background: transparent;
     cursor: pointer;
     width: 7.5rem;
 }
 
 /* Removes default focus */
 input[type="range"]:focus {
   outline: none;
 }
 
 /***** Chrome, Safari, Opera and Edge Chromium styles *****/
 /* slider track */
 input[type="range"]::-webkit-slider-runnable-track {
    background-color: #000000;
    border-radius: 0.5rem;
    height: 0.4rem;  
 }
 
 /* slider thumb */
 input[type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -8px; /* Centers thumb on the track */
    background-color: grey;
    border: 4px inset black;
    height: 1.5rem;
    width: 0.75rem;
 }
 
 input[type="range"]:focus::-webkit-slider-thumb {  
    background-color: #aa0009; 
 }