.WfSelector{
    display: flex;
    flex-direction: row;
}

.sublabel{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: -10px;
    padding: 0px 10px 0px 0px;
    transform: translateX(15px);
    /* background-color: aqua; */
}