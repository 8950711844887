.Note{
    display: flex;
    width: 40px;
    height: 40px;
    background: radial-gradient(#474747, #000000);
    border-radius: 10px;
    border: 3px inset black;
}

.Note.active{
    background: radial-gradient(#ffffff, #797979);
}

.Note.played{
    background: radial-gradient(goldenrod, rgb(70, 50, 0));
}

.Note.played.edit{
    background: radial-gradient(#ffb1b1, #aa0009);
}