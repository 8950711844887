.Controls{
    display: flex;
    flex-direction: row;
    max-height: 400px;
}

@media only screen and (max-width: 896px) {
    .Controls{
        max-height: 400px;
        flex-wrap: wrap;
        align-items: center;
        justify-self: center;
        overflow-x: scroll;
    }

    fieldset:nth-child(3){
        order: 2;
    }
}

@media only screen and (max-height: 650px) {
    .Controls{
        max-height: 200px;
    }
    
}