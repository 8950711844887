#Footer{
    display: flex;
    margin-top: auto;
    text-align: center;
    flex-direction: column;
    padding: 10px;
    color: grey;
}
@media screen and (max-height: 899px){
    #Footer{
        display: none;
    }
}
